@tailwind base;
@tailwind components;


body {
  @apply bg-gray-50;
}

.flex-center {
  @apply flex items-center justify-center;
}

.hue-filter-50:not(:disabled):hover,
button:not(:disabled, .no-highlight):hover {
  filter: brightness(105%) hue-rotate(2deg);
  transition: filter 0.15s ease-in-out;
}

@tailwind utilities;